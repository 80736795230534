.site-index{
	.brand{
		&__title{
			@include texRed();
			color: #FFF;
			text-align: center;
			font-size: 1.44rem;
			line-height: 1;
			letter-spacing: .08em;
			padding: 32px 0;
			@media screen and (max-width: $phone){
				padding: $sp-side;
				font-size: 1.24rem;
			}
			&:before{
				content: attr(data-eng);
				display: block;
				font-size: .88rem;
				margin: 0 0 8px;
			}
		}
		#brandSwipe{
			@include tex();
			padding: 32px 0;
			@media screen and (max-width: $phone){
				padding: 24px 0;	
			}
			.swiper-wrapper{
				transition-timing-function: linear;
			}
			.swiper-slide{
				width: 220px;
				margin: 0 16px;
				background: #FFF;
				position: relative;
				@media screen and (max-width: $phone){
					width: 120px;
					margin: 0 8px;
				}
				img{
					width: 100%;
				}
			}
		}
	}
	
	.news{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
        &__log{
			width: 940px;
			height: 180px;
			padding: 0 20px 0 0;
			overflow: auto;
			@media screen and (max-width: $tab){
				width: auto;
				height: 164px;
				padding: 0;
			}
            &__list{
                padding: 0 0 20px;
                margin: 0 0 20px;
                border-bottom: $border 1px solid;
                display: block;
				@media screen and (max-width: $phone){
					padding: 0 0 15px;
					margin: 0 0 15px;
				}
                &:last-child{
                    border: none;
                    margin: 0;
                }
                a{
                    color: $normal;
					position: relative;
					display: block;
					&:after{
						content: "\f0a9";
						font-size: .92rem;
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						top: 50%;
						right: 8px;
						transform: translateY(-50%);
					}
					&:hover{
						h4{
							text-decoration: underline;
						}
					}
                    .data{
                        @include flex();
                        justify-content: flex-start;
                        align-items: flex-start;
                        align-content: flex-start;
                        time,
                        .category{
                            display: inline-block;
                            width: 110px;
							padding: 2px 0;
                            text-align: center;
                            color: $normal;
							font-weight: 700;
                            background: darken($gray, 8%);
                            font-size: .8rem;
                            margin: 0 10px 0 0;
                        }
                        .category{
                            background: $red;
                            color: #FFF;
                        }
                        h4{
                            width: calc(100% - 240px);
                            font-size: 1.08rem;
							padding: 0 0 0 8px;
							transform: translateY(-1px);
							@media screen and (max-width: $phone){
								width: 100%;
								padding: 12px 0 0;
								font-size: 1rem;
							}
                        }
                    }
                }
            }
        }
        &__more{
			width: calc(100% - 940px - 24px);
			background: $red;
			color: #FFF;
			@include flex();
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			@media screen and (max-width: $tab){
				display: block;
				width: 300px;
				margin: 24px auto 0;
				padding: 12px 0;
				font-size: 1.08rem;
			}
			@media screen and (max-width: $phone){
				width: auto;
				font-size: 1.24rem;
			}
			&:hover{
				background: lighten($red, 4%);
			}
			&__box{
				display: block;
				text-align: center;
				i{
					display: block;
					font-size: 2rem;
					margin: 0 0 8px;
					@media screen and (max-width: $tab){
						display: inline-block;
						margin: 0 8px 0 0;
						font-size: 1.08rem;
					}
					@media screen and (max-width: $phone){
						font-size: 1.24rem;	
					}
				}
			}
        }
	}
	
	.concept{
		position: relative;
		&:before,
		&:after{
			content: '';
			width: 100%;
			height: 50%;
			@include tex();
			position: absolute;
			top: 76px;
			left: 0;
			z-index: -1;
			@media screen and (max-width: $tab){
				top: 40px;	
			}
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&:after{
			top: auto;
			left: auto;
			right: 0;
			bottom: 0;
			width: 60%;
		}
		.display{
		}
		&__pic{
			position: relative;
			top: 0;
			left: calc(4vw - 40px);
			width: calc(64% + 40px);
			height: 590px;
			margin: 0 0 48px;
			@media screen and (max-width: $tab){
				left: -$side;
				margin: 0;
			}
			@media screen and (max-width: $phone){
				left: 0;
				width: calc(100% + #{$sp-side*2});
				height: 56vw;
				left: -$sp-side;
				margin: 0 0 28px;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__box{
			background: #fff;
			width: 590px;
			position: absolute;
			top: 154px;
			right: 150px;
			padding: 66px 64px 62px;
			@media screen and (max-width: $tab){
				right: $side;
				top: 80px;
			}
			@media screen and (max-width: $phone){
				position: relative;
				width: auto;
				top: 0;
				right: auto;
				left: 0;
				padding: 0 $sp-side;
				transform: translate(0) !important;
			}
			dl{
				dt{
					@include min();
					font-size: 2.4rem;
					font-weight: 700;
					line-height: 1.66;
					@media screen and (max-width: $phone){
						font-size: 2rem;
						text-align: center;
					}
					&:before{
						content: 'CONCEPT';
						@include oswald();
						font-size: .96rem;
						color: $red;
						display: block;
						text-indent: 2px;
					}
				}
				dd{
					font-size: 1.04rem;
					text-align: justify;
					margin: 16px 0 0;
					@media screen and (max-width: $phone){
						font-size: .96rem;
						margin: 8px 0 0;
					}
					&.btnarea{
						margin: 32px 0 0;
						@media screen and (max-width: $phone){
							margin: 24px 0 0;	
						}
					}
				}
			}
		}
	}
	
	.recwrapper{
		position: relative;
		&.site-module{
			&.wrap{
				padding-top: 124px;
				padding-left: 4vw;
				padding-right: 4vw;
				margin-bottom: 32px;
				@media screen and (max-width: $tab){
					padding: 64px $side 0;
					margin: 0 0 64px;
				}
				@media screen and (max-width: $phone){
					padding: 24px 0 48px;
					margin: 0;
				}
			}
		}
		&__title{
			position: absolute;
			top: 96px;
			left: 50%;
			@include oswald();
			color: $red;
			font-size: 4rem;
			letter-spacing: .4em;
			font-weight: 700;
			line-height: 1;
			transform: translateX(-50%);
			pointer-events: none;
			z-index: 20;
			@media screen and (max-width: $phone){
				top: 6px;
				font-size: 2.8rem;
			}
		}
	}
	.reclist{
		@include flex();
		justify-content: center;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&__item{
			width: 33.3%;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 $sp-side $sp-side;
				&:last-child{
					margin-bottom: 0;
				}
			}
			a{
				display: block;
				position: relative;
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: #000;
					z-index: 2;
					opacity: .72;
					transition: opacity 500ms linear;
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 16px 16px;
					border-color: transparent transparent $red transparent;
					position: absolute;
					right: 8px;
					bottom: 8px;
					z-index: 3;
				}
				&:hover{
					&:before{
						opacity: .32;
					}
					img{
						opacity: 1;
						transform: translate(-50%, -50%) scale(1.1);
						transition: transform 1600ms linear;
					}
				}
				figure{
					width: 100%;
					padding: 72.5% 0 0;
					min-height: 300px;
					position: relative;
					top: 0;
					left: 0;
					overflow: hidden;
					@media screen and (max-width: $phone){
						min-height: 200px;
						padding: 56% 0 0;
					}
					img{
						display: block;
						width: auto;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: transform 500ms linear;
						@media screen and (max-width: $phone){
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							transform: translate(0);
							object-fit: cover;
							object-position: center;
						}
					}
				}
				dl{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #FFF;
					text-align: center;
					font-weight: 700;
					z-index: 3;
					dt{
						font-size: 1.72rem;
						letter-spacing: .12em;
						line-height: 1.5;
						white-space: nowrap;
					}
					dd{
						@include oswald();
						text-transform: uppercase;
						font-size: 12px;
						&.arrow{
							width: 40px;
							height: 40px;
							font-size: 12px;
							margin: 20px auto 0;
							border: #FFF 1px solid;
							border-radius: 50%;
							@include flex();
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
	}
	
	.franchise{
		position: relative;
		&:after{
			content: '';
			width: 100%;
			height: 12px;
			@include texRed();
			position: absolute;
			left: 0;
			bottom: 0;
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&.site-module{
			&.wrap{
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
			}
		}
	}
	.twosection{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
		&__item{
			width: 50%;
			@include flex();
			justify-content: flex-start;
			height: 100%;
			@media screen and (max-width: $tab){
				display: block;
				width: auto;
				height: auto;
				margin: 0 $side;
			}
			@media screen and (max-width: $phone){
				margin: 0;	
			}
			&:first-child{
				justify-content: flex-end;
			}
			figure{
				position: relative;
				z-index: 1;
				width: 100%;
				height: 360px;
				overflow: hidden;
				@media screen and (max-width: $phone){
					height: 56vw;	
				}
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(1.01);
					width: 100%;
					height: auto;
					@media screen and (max-width: $phone){
						position: relative;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						transform: translate(0);
						object-fit: cover;
						object-position: center;
					}
				}
			}
			&__box{
				position: relative;
				z-index: 2;
				background: #FFF;
				color: $normal;
				min-width: 440px;
				max-width: 600px;
				width: calc(46vw - #{$side});
				padding: 40px;
				margin: 0 $side;
				transform: translateY(-80px);
				box-shadow: 12px 12px 56px rgba(#000,.12);
				text-align: center;
				@media screen and (max-width: $tab){
					width: auto;
					min-width: 1px;
					max-width: 100%;
					display: block;
				}
				@media screen and (max-width: $phone){
					padding: 20px $sp-side*1.5;
					margin: 0 $sp-side;
					transform: translateY(-24px);
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 12px 12px;
					border-color: transparent transparent $red transparent;
					position: absolute;
					right: 8px;
					bottom: 8px;
					z-index: 3;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
				h4{
					font-size: 1.56rem;
					font-weight: 700;
					line-height: 1;
					margin: 0 0 16px;
					letter-spacing: .032em;
					&:before{
						@include oswald;
						text-transform: uppercase;
						content: attr(data-eng);
						font-size: .88rem;
						letter-spacing: .12em;
						color: $red;
						display: block;
						margin: 0 0 8px;
					}
				}
				p{
					text-align: justify;
				}
			}
		}
	}
	
	.contact{
		height: 440px;
		background: url(/images/index/contact_bg.jpg) no-repeat center;
		background-size: cover;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			height: 280px;	
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .56;
		}
		&__sec{
			color: #FFF;
			position: relative;
			z-index: 2;
			text-align: center;
			mix-blend-mode: ececute;
			dt{
				font-size: 1.1rem;
				line-height: 1.6;
				&:after{
					content: attr(data-eng);
					@include oswald();
					font-size: 3.2rem;
					letter-spacing: .012em;
					line-height: 1;
					display: block;
					margin: 8px 0 0;
					@media screen and (max-width: $phone){
						font-size: 2rem;	
					}
				}
			}
		}
	}
}