.secondEl{
	width: 100%;
	height: 100vh;
	padding: $pc-head 0 0;
	background: #000;
	@include flex();
	align-items: center;
	justify-content: center;
	color: $white;
	overflow: hidden;
	position: relative;
    @media screen and (max-width: $tab){
        height: 640px;	
    }
    @media screen and (max-width: $phone){
        height: 360px;
		padding: $sp-head 0 0;
    }
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $normal;
		opacity: .24;
		z-index: 2;
	}
	a{
		color: $white;
	}
	&__box{
		position: relative;
		text-align: center;
		z-index: 3;
		margin: -4vh 0 0;
		@media screen and (max-width: $tab){
			margin: 20px 0 0;
		}
		@media screen and (max-width: $phone){
			padding: 0 $sp-side;	
		}
		&__eng{
			@include min();
			font-size: 7.2rem;
			letter-spacing: .024em;
			font-weight: 700;
			line-height: 1;
			opacity: 0;
			@media screen and (max-width: $phone){
				font-size: 2.8rem;	
			}
			span{
				opacity: 0;
				display: inline-block;
				transform: translateY(20px);
				@media screen and (max-width: $phone){
					transform: translateY(10px);
				}
			}
		}
		h1{
			font-size: 1.88rem;
			line-height: 1.6;
			margin: 16px 0 0;
			opacity: 0;
			transform: translateY(20px);
			@media screen and (max-width: $phone){
				font-size: 1.2rem;
				transform: translateY(10px);
				margin: 10px 0 0;
			}
			strong{
				font-weight: 500;
				letter-spacing: .08em;
			}
		}
	}
	.breadcrumb{
		position: absolute;
		top: 96px;
		left: $side;
		z-index: 3;
		@media screen and (max-width: $phone){
			top: $sp-head+10px;
			left: $sp-side;
		}
        ol{
            @include flex();
            align-items: center;
            justify-content: flex-start;
            li{
                list-style: none;
                font-size: .8rem;
                color: $white;
				@media screen and (max-width: $phone){
					font-size: .72rem;	
				}
                &:after{
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: .64rem;
                    font-weight: 900;
                    opacity: .4;
                    display: inline-block;
                    margin: 0 16px;
                    transform: translateY(-2px);
					@media screen and (max-width: $phone){
						margin: 0 10px;
						transform: translateY(0);
					}
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: $white;
                    &:hover{
                        color: $red;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
						opacity: .88;
                        font-size: .88rem;
                    }
                }
            }
        }
	}
	&__bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-position: center;
		background-size: cover;
		transform: scale(1.08);
		opacity: 0;
	}
	&__video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		video{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&__parallax{
		position: absolute;
		left: 0;
		top: 100%;
		background: #FFF;
		width: 100%;
		height: 100vh;
		z-index: 10;
	}
	&.active{
		.secondEl__box{
			.secondEl__box__eng{
				opacity: 1;
				span{
					transform: translateY(0);
					opacity: 1;
					@for $i from 1 through 100{
						&:nth-of-type(#{$i}){
							transition: opacity 600ms $i*80ms linear, transform 600ms $i*80ms linear;
						}
					}
				}
			}
			h1{
				transform: translateY(0);
				opacity: 1;
				transition: opacity 800ms 1200ms linear, transform 800ms 1200ms ease-out;
			}
		}
		.secondEl__bg{
			transform: scale(1);
			opacity: 1;
			transition: opacity 600ms linear, transform 4000ms ease-in-out;
		}
	}
}