.site-property{
	.propdemo{
		height: 500px;
		@include flex();
		align-items: center;
		justify-content: center;
		background: $gray;
		font-size: 1.66rem;
	}
	.propksn{
		background: url(/images/property/kussion.jpg) no-repeat center / cover;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		color: #FFF;
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .64;
			z-index: 1;
		}
		dl{
			text-align: center;
			position: relative;
			z-index: 2;
			font-size: 1.16rem;
			@media screen and (max-width: $phone){
				font-size: 1rem;	
			}
			dt{
				font-size: 2rem;
				font-weight: 700;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					font-size: 1.44rem;
					margin: 0 0 6px;
				}
			}
			dd{
			}
		}
	}
	.over{
		@media screen and (max-width: $phone){
			width: 100%;
			overflow: auto;
			position: relative;
			&:before{
				content: '▼ スクロールしてご覧ください';
				display: block;
				margin: 0 0 8px;
			}
		}
		&__table{
			border: $border 1px solid;
			border-collapse: collapse;
			width: 100%;
			@media screen and (max-width: $phone){
				width: 200%;	
			}
			thead,
			tbody{
				tr{
					th,
					td{
						vertical-align: middle;
						padding: 24px;
						border: $border 1px solid;
						text-align: center;
						font-size: 1.08rem;
						@media screen and (max-width: $phone){
							font-size: 1rem;
							padding: 15px;
						}
						&.left{
							text-align: left;
						}
					}
					th{
						background: $gray;
					}
				}
			}
			thead{
				tr{
					td{
						figure{
							img{
								width: 50%;
								border: $border 1px solid;
								@media screen and (max-width: $phone){
									width: 64%;	
								}
							}
						}
					}
				}
			}
			tbody{
				tr{
					td{
						width: 28%;
					}
				}
			}
		}
	}
}