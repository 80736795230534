.site-article{
	.newslist{
		li{
            margin: 0 0 24px;
            padding: 0 0 24px;
            border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				padding: 0 0 15px;
			}
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            a{
                @include flex();
                color: $normal;
                figure{
                    width: 240px;
                    height: 160px;
					@media screen and (max-width: $phone){
						width: 100px;
						height: 75px;
					}
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                dl{
                    width: calc(100% - 240px);
                    padding: 0 0 0 32px;
					@media screen and (max-width: $phone){
						width: calc(100% - 100px);
						padding: 0 0 0 20px;
					}
                    .meta{
						@include flex();
						align-items: center;
						justify-content: flex-start;
						@media screen and (max-width: $phone){
							margin: 0;	
						}
						time{
							width: 100px;
							padding: 2px 0;
							text-align: center;
							background: darken($gray, 2%);
							font-weight: 700;
							font-size: .8rem;
							margin: 0 10px 0 0;
							@media screen and (max-width: $phone){
								width: 88px;
							}
						}
                        .category{
                            background: $red;
                            color: #FFF;
                            width: 110px;
							padding: 2px 0;
                            text-align: center;
							font-weight: 700;
                            font-size: .8rem;
                            margin: 0 10px 0 0;
							@media screen and (max-width: $phone){
								width: auto;
								min-width: 110px;
								padding: 2px 10px;
							}
                        }
                    }
                    dt{
                        margin: 12px 0 0;
                        line-height: 1.6;
                        font-weight: 700;
						font-size: 1.44rem;
						@media screen and (max-width: $phone){
							font-size: 1.12rem;
							margin: 8px 0 0;
						}
                    }
					dd{
						margin: 8px 0 0;
						line-height: 1.66;
						@media screen and (max-width: $phone){
							display: none;
						}
					}
                }
            }
		}
	}
	
	.twoclum{
		@include flex();
		align-items: flex-start;
		@media screen and (max-width: $tab){
			display: block;	
		}
		&__sidebar{
			width: 300px;
			order: 1;
			position: sticky;
			top: $pc-head + 20px;
			@media screen and (max-width: $tab){
				position: relative;
				top: 0;
				width: auto;
			}
			@media screen and (max-width: $phone){
				margin: 24px 0 0;	
			}
			.mt{
				margin-top: 48px;
				@media screen and (max-width: $phone){
					margin-top: 24px;	
				}
			}
			.list{
				li{
					margin: 0 0 20px;
					padding: 0 0 20px;
					border-bottom: $border 1px solid;
					&:last-child{
						margin: 0;
						padding: 0;
						border: none;
					}
					a{
						@include flex();
						color: $normal;
						figure{
							width: 120px;
							height: 80px;
							@media screen and (max-width: $phone){
								width: 100px;
								height: 75px;
							}
							img{
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
						dl{
							width: calc(100% - 120px);
							padding: 0 0 0 20px;
							@media screen and (max-width: $phone){
								width: calc(100% - 100px);	
							}
							.time{
								width: 100px;
								padding: 1px 0;
								text-align: center;
								background: darken($gray, 2%);
								font-weight: 700;
								font-size: .8rem;
							}
							dt{
								margin: 6px 0 0;
								line-height: 1.6;
								font-weight: 700;
							}
						}
					}
				}
			}
			.catelist{
				@include flex();
				justify-content: flex-start;
				align-items: flex-start;
				li{
					display: inline-block;
					margin: 0 8px 8px 0;
					a{
						display: block;
						background: darken($gray, 4%);
						color: $normal;
						padding: 3px 20px;
						border-radius: 3px;
						font-size: .88rem;
						&:hover{
							background: $red;
							color: #FFF;
						}
					}
				}
			}
		}
		&__container{
			width: calc(100% - 300px);
			padding: 0 0 0 48px;
			order: 2;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 0;
			}
			&__title{
				font-size: 1.56rem;
				font-weight: 700;
				border-top: $normal 2px solid;
				border-bottom: $normal 2px solid;
				font-weight: 700;
				padding: 8px 2px;
				margin: 0 0 20px;
			}
			&__date{
				text-align: right;
			}
		}
	}
}