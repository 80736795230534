#site-footer{
	background: $black;
	color: $white;
	a{
		color: $white;
	}
	.pagetop{
        background: url(/images/common/red_tex_2x.png) repeat;
        background-size: 170px;
		color: #FFF;
		text-align: center;
		padding: 16px 0;
		display: block;
		position: relative;
		@media screen and (max-width: $phone){
			padding: 12px $sp-side;	
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#FFF,.08);
			opacity: 0;
		}
		&:hover{
			&:before{
				opacity: 1;
			}
		}
	}
	
	.company{
		@include flex();
		align-items: center;
		justify-content: flex-start;
		margin: 64px 0 0;
		position: relative;
		@media screen and (max-width: $tab){
			padding: 0 $side;	
		}
		@media screen and (max-width: $phone){
			padding: 0 $sp-side;	
		}
		&__logo{
			width: 72px;
			margin: 0 20px 0 0;
			@media screen and (max-width: $phone){
				width: 56px;
				margin: 0 12px 0 0;
			}
			img{
				width: 100%;
			}
		}
		&__title{
			margin: 0 24px 0 0;
			padding: 0 24px 0 0;
			border-right: rgba(#FFF,.16) 1px solid;
			@media screen and (max-width: $phone){
				margin: 0;
				padding: 0;
				border: none;
			}
			dt{
				font-size: 1.48rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 5px;
				@media screen and (max-width: $phone){
					font-size: 1.24rem;	
				}
			}
			dd{
				font-size: .8rem;
			}
		}
		&__meta{
			font-size: 1rem;
			@media screen and (max-width: $phone){
				margin: 8px 0 0;
				font-size: .88rem;
			}
			li{
				margin: 0 20px 0 0;
				&:last-child{
					margin: 0;
				}
			}
		}
		&__sns{
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include flex();
			@media screen and (max-width: $tab){
				right: $side;	
			}
			@media screen and (max-width: $phone){
				right: $sp-side;
				display: block;
			}
			li{
				margin: 0 12px 0 0;
				@media screen and (max-width: $phone){
					margin: 0 0 12px;	
				}
				&:last-child{
					margin: 0;
				}
				a{
					width: 36px;
					height: 36px;
					background: rgba($white, .12);
					border-radius: 50%;
					@include flex();
					align-items: center;
					justify-content: center;
					transition: all 200ms $ease;
					&:hover{
						transform: scale(1.1);
						background: rgba($white, .24);
					}
					i{
						font-size: 1rem;
					}
				}
			}
		}
	}

	.fnav{
		@include flex();
		padding: 64px 0;
		margin: 0 0 0 -40px;
		@media screen and (max-width: $tab){
			padding: 48px $side;	
		}
		@media screen and (max-width: $phone){
			padding: 32px $sp-side;
			margin: 0;
			display: block;
		}
		> li{
			width: 25%;
			padding: 0 0 0 40px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 20px;
				&:last-child{
					margin: 0;
				}
			}
			> dl{
				@media screen and (max-width: $phone){
					border: rgba(#FFF,.12) 1px solid;
					border-radius: 4px;
					overflow: hidden;
				}
				dt{
					font-size: 1.08rem;
					font-weight: 700;
					padding: 0 0 20px;
					position: relative;
					text-indent: .1em;
					@media screen and (max-width: $phone){
						padding: 10px $sp-side;
						background: rgba(#FFF,.12);
					}
					&:before,
					&:after{
						content: '';
						width: 100%;
						height: 1px;
						position: absolute;
						left: 0;
						bottom: 8px;
						background: rgba(#FFF,.1);
						@media screen and (max-width: $phone){
							display: none;
						}
					}
					&:after{
						background: $red;
						width: 32px;
					}
				}
				dd{
					font-size: .8rem;
					@media screen and (max-width: $phone){
						ul{
							li{
								border-bottom: rgba(#FFF,.12) 1px solid;
								&:last-child{
									border: none;
								}
							}
						}
					}
					a{
						@media screen and (max-width: $phone){
							display: block;
							padding: 12px $sp-side;
							font-size: 1.08rem;
							position: relative;
							&:after{
								content: "\f054";
								font-size: .72rem;
								font-family: "Font Awesome 5 Free";
								font-weight: 900;
								position: absolute;
								top: 50%;
								right: $sp-side;
								transform: translateY(-50%);
								opacity: .4;
							}
						}
						&:hover{
							text-decoration: underline;
						}
						i{
							font-size: .8rem;
							transform: scale(.9);
							display: inline-block;
							color: $red;
							margin: 0 0 0 6px;
						}
					}
				}
			}
		}
	}
	
	.copy{
		display: block;
		padding: 24px 0;
		text-align: center;
		font-size: .72rem;
		border-top: rgba(#FFF,.08) 1px solid;
	}
}