.site-shop{
	.propnav{
		&__list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -20px;
			@media screen and (max-width: $tab){
				margin: 0 0 0 -12px;	
			}
			@media screen and (max-width: $phone){
				margin: 0 0 0 -8px;	
			}
			&__item{
				width: 20%;
				padding: 0 0 0 20px;
				margin: 0 0 20px;
				@media screen and (max-width: $tab){
					width: 33.3%;
					padding: 0 0 0 12px;
					margin: 0 0 12px;
				}
				@media screen and (max-width: $phone){
					width: 50%;
					padding: 0 0 0 8px;
					margin: 0 0 8px;
				}
				a{
					display: block;
					text-align: center;
					background: $gray;
					padding: 12px 0;
					color: $normal;
					font-size: 1.12rem;
					@media screen and (max-width: $phone){
						font-size: 1.08rem;	
					}
					&:hover{
						background: $red;
						color: #FFF;
					}
				}
			}
		}
	}
	.offlink{
		text-align: center;
		margin: 0 0 32px;
		a{
            display: block;
            background: #FFF;
            border: $red 2px solid;
            color: $red;
            border-radius: 3px;
            padding: 12px 0;
            font-size: 1.24rem;
            font-weight: 700;
            text-align: center;
			i{
				display: inline-block;
				margin: 0 0 0 8px;
				font-size: .88rem;
				transform: translateY(-2px);
			}
            &:hover{
                background: $red;
                color: #FFF;
            }
		}
	}
	.shoplist{
		margin: 24px 0 0;
		&__item{
			margin: 0 0 56px;
			@media screen and (max-width: $phone){
				margin: 0 0 32px;	
			}
			&:last-child{
				margin: 0;
			}
            &__box{
                @include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
                &__pic{
                    width: 500px;
					height: 400px;
					@media screen and (max-width: $tab){
						width: 400px;
						height: 400px;
					}
					@media screen and (max-width: $phone){
						width: auto;
						height: 56vw;
					}
					&.contain{
						height: 295px;
						border: $border 1px solid;
						padding: 32px;
						@media screen and (max-width: $phone){
							height: auto;
						}
						img{
							object-fit: contain;
						}
					}
                    img{
                        width: 100%;
                        height: 100%;
						object-fit: cover;
						object-position: center;
                    }
                }
                &__data{
                    width: calc(100% - 500px);
					padding: 0 0 0 32px;
					@media screen and (max-width: $tab){
						width: calc(100% - 400px);
					}
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
						margin: 20px 0 0;
					}
					.info{
						@include flex();
						align-items: center;
						margin: 0 0 8px;
						&:last-of-type{
							margin: 0;
						}
						dt{
							width: 120px;
							background: $gray;
							text-align: center;
							padding: 4px 0;
							font-size: .92rem;
						}
						dd{
							width: calc(100% - 120px);
							padding: 0 0 0 20px;
							font-size: 1rem;
							a{
								color: $red;
								font-weight: 700;
								i{
									display: inline-block;
									transform: rotateY(180deg);
									margin: 0 6px 0 0;
									color: $red;
								}
							}
						}
					}
					.map{
						width: 100%;
						height: 170px;
						margin: 16px 0 0;
						padding: 8px;
						border: $gray 1px solid;
						border-radius: 2px;
						@media screen and (max-width: $tab){
							height: 140px;	
						}
						iframe{
							width: 100%;
							height: 100%;
							vertical-align: bottom;
						}
					}
					.links{
						@include flex();
						justify-content: flex-start;
						padding: 8px 0 0;
						margin: 0 0 0 -8px;
						li{
							padding: 0 0 0 8px;
							margin: 8px 0 0;
							width: 33.3%;
							@media screen and (max-width: $tab){
								width: 50%;	
							}
							a{
								display: block;
								border: $red 2px solid;
								background: $red;
								color: #FFF;
								padding: 6px 0;
								border-radius: 2px;
								text-align: center;
								font-size: .96rem;
								&:hover{
									background: #FFF;
									color: $red;
								}
							}
						}
					}
                }
            }
		}
		.largelist{
			background: darken($gray, 1%);
			width: 100%;
			margin: 24px 0 0;
			border-radius: 3px;
			@include flex();
			align-items: center;
			justify-content: center;
			padding: 24px;
			@media screen and (max-width: $phone){
				display: block;
				padding: $sp-side;
			}
			li{
				width: 320px;
				margin: 0 8px;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 8px;
					&:last-child{
						margin: 0;
					}
				}
				a{
					display: block;
					background: #FFF;
					border: $red 2px solid;
					color: $red;
					border-radius: 3px;
					padding: 12px 0;
					font-size: 1.24rem;
					font-weight: 700;
					text-align: center;
					&:hover{
						background: $red;
						color: #FFF;
					}
				}
			}
		}
	}
	
	.alertment{
		margin: 32px 0 0;
		border: $gray 8px solid;
		border-radius: 3px;
		padding: 24px 48px;
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		dt{
			font-size: 1.44rem;
			font-weight: 700;
			text-align: center;
			margin: 0 0 20px;
			@media screen and (max-width: $phone){
				font-size: 1.24rem;
				margin: 0 0 12px;
			}
		}
		dd{
			font-size: 1.08rem;
			small{
				font-size: .96rem;
			}
		}
		.list{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(50% - 8px);
				margin: 16px 0 0;
				background: $gray;
				padding: 15px;
				font-weight: 700;
				text-align: center;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 12px 0 0;
					padding: $sp-side;
				}
			}
		}
		.small{
			margin: 16px 0;
			@media screen and (max-width: $phone){
				margin: 8px 0 0;	
			}
			&__p{
				font-size: .96rem;
			}
		}
	}
	
	.photos{
		@include flex();
		justify-content: flex-start;
		margin: 40px 0 0 -24px;
		@media screen and (max-width: $phone){
			margin: 24px 0 0 -12px;	
		}
		li{
			width: 25%;
			padding: 0 0 0 24px;
			margin: 24px 0 0;
			@media screen and (max-width: $phone){
				width: 50%;
				padding: 0 0 0 12px;
				margin: 12px 0 0
			}
			&:nth-child(-n+4){
				margin-top: 0;
				@media screen and (max-width: $phone){
					margin: 12px 0 0;	
				}
			}
			@media screen and (max-width: $phone){
				&:nth-child(-n+2){
					margin-top: 0;
				}	
			}
			img{
				width: 100%;
			}
		}
	}
}