.site-bnr{
	&.site-module{
		&.wrap{
			&.mid{
				padding-bottom: 40px;
				@media screen and (max-width: $phone){
					padding-bottom: 24px;	
				}
			}
		}
	}
	.bnrlist{
		&__item{
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				margin: 0 0 12px;	
			}
			&:last-child{
				margin: 0;
			}
			figure{
				&.pc{
					display: block;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
				&.sp{
					display: none;
					@media screen and (max-width: $phone){
						display: block;
					}
				}
				img{
					max-width: 100%;
					height: auto;
				}
			}
			a{
				display: block;
			}
		}
	}
}