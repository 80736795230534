.site-index{
	.firstview{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: $black;
		@media screen and (max-width: $tab){
			height: 640px;	
		}
		@media screen and (max-width: $phone){
			height: 360px;	
		}
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			video{
				object-fit: cover;
				object-position: center;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}
			&:after{
				content: '';
				width: 100%;
				height: $pc-head*2;
				position: absolute;
				background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
				top: 0;
				left: 0;
				z-index: 2;
			}
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				background: $black;
				top: 0;
				left: 0;
				opacity: .24;
				z-index: 2;
			}
		}
		&__title{
			position: absolute;
			top: 50%;
			left: 9vw;
			transform: translateY(-50%);
			z-index: 10;
			color: $white;
			@media screen and (max-width: $tab){
				top: calc(50% + #{$pc-head/4});
			}
			@media screen and (max-width: $phone){
				top: calc(50% + #{$sp-head/2});
				left: 6.6vw;	
			}
			h1{
				font-family: 'Roboto', sans-serif;
				font-size: 10rem;
				font-weight: 700;
				line-height: 1;
				letter-spacing: 0em;
				transform: translateX(-4px);
				@media screen and (max-width: $tab){
					font-size: 8rem;	
				}
				@media screen and (max-width: $phone){
					font-size: 5.2rem;	
				}
				.indent{
					display: inline-block;
					text-indent: .1em;
				}
			}
			p{
				@include min();
				font-size: 1.44rem;
				line-height: 1.8;
				letter-spacing: .064em;
				margin: 12px 0 0;
				@media screen and (max-width: $phone){
					font-size: .96rem;
					line-height: 1.66;
					font-weight: 700;
					margin: 6px 0 0;
				}
				
			}
		}
		&__copy{
			position: absolute;
			left: $side;
			bottom: 32px;
			color: rgba($white, .64);
			font-size: .72rem;
			transform-origin: left bottom;
			letter-spacing: .12em;
			z-index: 2;
			padding: 0 0 0 48px;
			@media screen and (max-width: $phone){
				display: none;
			}
			&:before{
				content: '';
				width: 32px;
				height: 1px;
				background: $white;
				position: absolute;
				top: 50%;
				left: 0;
				opacity: .32;
			}
		}
		&__scroll{
			content: '';
			width: 88px;
			height: 88px;
			border: rgba($white, .32) 1px solid;
			border-radius: 50%;
			position: absolute;
			right: $side;
			bottom: 30px;
			z-index: 2;
			transition: border 400ms;
			@media screen and (max-width: $tab){
				display: none;
			}
			@media screen and (max-width: $phone){
				bottom: $sp-side;
				right: $sp-side;
			}
			&:hover{
				border: $white 1px solid;
			}
			&:before{
				$size: 20px;
				content: '';
				width: 1px;
				height: 20px;
				background: $white;
				position: absolute;
				top: 20px;
				left: 50%;
				animation: s 1200ms $ease infinite;
				@keyframes s{
					0%{
						top: $size;
						height: 0;
					}
					50%{
						top: $size;
						height: $size;
					}
					51%{
						top: $size;
						height: $size;
					}
					100%{
						top: $size*2;
						height: 0;
					}
				}
			}
			&__txt{
				position: absolute;
				left: 50%;
				bottom: 20px;
				font-size: .72rem;
				transform-origin: center bottom;
				transform: translate(-50%) scale(.8);
				letter-spacing: .12em;
				color: $white;
			}
		}
	}
}