.site-fc{
	.fclist{
		&__item{
			margin: 0 0 24px;
			&:last-child{
				margin: 0;
			}
			&__box{
				padding: 24px;
				color: $normal;
				background: $gray;
				height: 100%;
				@include flex();
				align-items: flex-start;
				@media screen and (max-width: $phone){
					padding: $sp-side;
					display: block;
				}
                h3{
					width: 100%;
                    font-size: 1.44rem;
                    font-weight: 700;
					line-height: 1.6;
					margin: 0 0 16px;
					padding: 0 0 12px;
					border-bottom: #FFF 2px solid;
                }
				&__pic{
					width: 300px;
					height: 300px;
					@media screen and (max-width: $phone){
						width: 100%;
						height: 56vw;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&__data{
					width: calc(100% - 300px);
					padding: 0 0 0 40px;
					@media screen and (max-width: $phone){
						width: auto;
						margin: 24px 0 0;
						padding: 0;
					}
					p{
						font-size: 1.04rem;
						line-height: 1.66;
						text-align: justify;
					}
					.btncrone{
						background: $red;
						color: #FFF;
						display: inline-block;
						position: relative;
						padding: 10px 56px;
						padding-right: 64px;
						font-size: 1.16rem;
						margin: 20px 0 0;
						@media screen and (max-width: $phone){
							padding: 10px 0;
							text-align: center;
							display: block;
						}
						i{
							position: absolute;
							top: 50%;
							right: 15px;
							transform: translateY(-50%);
							font-size: .72rem;
						}
					}
				}
			}
		}
	}
	
	.voicewrap{
		position: relative;
		.ami{
			@include ami(rgba($red,.99), 12px);
			width: 20vw;
			height: 20vw;
			position: absolute;
			@media screen and (max-width: $phone){
				display: none;
			}
			&.pos1{
				left: 4vw;
				top: 16vh;
			}
			&.pos2{
				right: 4vw;
				bottom: 16vh;
			}
		}
		.voicelist{
			position: relative;
			z-index: 2;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(50% - 12px);
				border: $gray 8px solid;
				background: #FFF;
				padding: 24px;
				position: relative;
				@include flex();
				margin: 24px 0 0;
				@media screen and (max-width: $phone){
					width: auto;
					display: block;
					margin: 0 0 16px;
					padding: $sp-side;
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						&:before{
							content: 'ORNERS VOICE.#{$i}';
						}
					}
				}
				&:before{
					position: absolute;
					top: -16px;
					right: 24px;
					background: $red;
					font-size: .88rem;
					padding: 8px 16px;
					color: #FFF;
					box-shadow: 8px 8px 16px rgba(#000,.08);
					@media screen and (max-width: $phone){
						font-size: .8rem;
						padding: 4px 12px;
					}
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 0 0 8px;
					border-color: transparent transparent transparent darken($red,16%);
					position: absolute;
					top: -16px;
					right: 24px;
					transform: translateX(100%);
				}
				.ttl{
					width: 100%;
					@include flex();
					align-items: center;
					figure{
						width: 100px;
						height: 100px;
						border-radius: 50%;
						overflow: hidden;
						@media screen and (max-width: $phone){
							width: 80px;
							height: 80px;
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					h3{
						width: calc(100% - 100px);
						padding: 0 0 0 20px;
						font-weight: 700;
						font-size: 1.44rem;
						line-height: 1.5;
						@media screen and (max-width: $phone){
							width: calc(100% - 80px);
							font-size: 1.24rem;
							padding-left: 15px;
						}
						strong{
							display: block;
							font-size: 1.2rem;
							@media screen and (max-width: $phone){
								font-size: 1rem;
							}
						}
					}
				}
				.content{
					width: 100%;
					margin: 16px 0 0;
					text-align: justify;
					strong{
						color: $red;
						background: yellow;
					}
				}
			}
		}
	}
	
	.flow{
		margin: 0 0 0 -40px;
		@include flex();
		justify-content: flex-start;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0;
		}
		&__item{
			width: 33.3%;
			padding: 0 0 0 40px;
			margin-top: 40px;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 20px;
			}
			@for $i from 1 through 6{
				&:nth-child(#{$i}){
					&:before{
						content: '0#{$i}';
						position: absolute;
						top: 0;
						left: 40px;
						width: 40px;
						height: 40px;
						align-items: center;
						justify-content: center;
						background: $red;
						color: #FFF;
						font-size: .96rem;
						@include oswald();
						@include flex();
						justify-content: center;
						align-items: center;
						z-index: 2;
						@media screen and (max-width: $phone){
							left: 0;	
						}
					}
					figure{
						&:before{
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 20px 0 20px 16px;
                            border-color: transparent transparent transparent darken($gray, 12%);
                            position: absolute;
                            right: -12px;
                            top: 50%;
                            transform: translate(100%, -50%);
							@media screen and (max-width: $phone){
								display: none;
							}
						}
					}
				}
			}
			&:nth-child(-n+3){
				margin-top: 0;
			}
			&:last-child {
				figure{
					&:before{
						display: none;
					}
				}
			}
			figure{
				position: relative;
				img{
					width: 100%;
				}
				figcaption{
					text-align: center;
					margin: 12px 0 0;
				}
			}
		}	
	}
	
	.conksin{
		background: url(/images/fc/fc_kussion.jpg) no-repeat center / cover;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		color: #FFF;
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .48;
			z-index: 1;
		}
		dl{
			text-align: center;
			position: relative;
			z-index: 2;
			font-size: 1.16rem;
			dt{
				font-size: 2rem;
				font-weight: 700;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					font-size: 1.56rem;
					margin: 0 0 6px;
				}
			}
			dd{
				@media screen and (max-width: $phone){
					padding: 0 $sp-side;	
				}
			}
		}
	}
	
	.fcmain{
		img{
			width: 100%;
		}
	}
	.fcstrong{
		margin: 48px 0 0;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		li{
			background: $gray;
			padding: 24px;
			@include flex();
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				padding: $sp-side;
				margin: 0 0 20px;
			}
			&:last-child{
				margin: 0;
			}
			figure{
				width: 400px;
				height: 250px;
				@media screen and (max-width: $phone){
					width: 100%;
					height: 56vw;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			dl{
				width: calc(100% - 400px);
				padding: 0 16px 0 40px;
				@media screen and (max-width: $phone){
					background: #FFF;
					padding: $sp-side;
					width: auto;
					margin: $sp-side 0 0;
				}
				dt{
					font-size: 1.64rem;
					line-height: 1.6;
					font-weight: 700;
					position: relative;
					padding: 0 0 0 28px;
					@media screen and (max-width: $phone){
						font-size: 1.2rem;
						padding: 0 0 0 20px;
					}
					&:before{
						content: '';
						width: 13px;
						height: 13px;
						background: $red;
						border-radius: 2px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}
				dd{
					text-align: justify;
					margin: 12px 0 0;
					padding: 12px 0 0;
					border-top: #FFF 2px solid;
					@media screen and (max-width: $phone){
						border-color: $border;	
					}
				}
			}
		}
	}
}