#site-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $pc-head;
	padding: 0 $side;
	z-index: 1000;
	@include flex();
	align-items: center;
	@media screen and (max-width: $phone){
		height: $sp-head;
		padding: 0 $sp-side;	
	}
	&:after{
		content: '';
		width: calc(100% - #{$side*2});
		height: 1px;
		position: absolute;
		left: $side;
		bottom: 0;
		background: rgba($white,.12);
		@media screen and (max-width: $phone){
			width: 	calc(100% - #{$sp-side*2});
			left: $sp-side;
		}
	}
	@media screen and (min-width: $tab + 1px){
		&.active,
		&.plane{
			position: fixed;
			background: #FFF;
			animation: view 600ms $ease forwards;
			@keyframes view{
				0%{
					transform: translateY(-100%);
				}
				100%{
					transform: translateY(0);
				}
			}
			.gnavi{
				.gnavi__list{
					li{
						a,
						dl dt a{
							color: $normal;
							&:after{
								color: $red;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&.plane{
		animation: none !important;
	}
	.logo{
		height: 52px;
		@media screen and (max-width: $tab){
			height: 48px;	
		}
		@media screen and (max-width: $phone){
			height: 40px;	
		}
		img{
			height: 100%;
		}
	}
	
	.gnavi{
		@media screen and (min-width: $tab + 1px){
			display: block !important;
			margin-left: -40px;
		}
		@media screen and (max-width: $tab){
			display: block;
			position: fixed;
			padding: 80px 0 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $black;
			overflow: auto;
			z-index: 9999;
			visibility: hidden;
			&.active{
				visibility: visible;
			}
			@media screen and (max-width: $phone){
				padding: 64px 0 0;	
			}
		}
		&__list{
			@include flex();
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $tab){
				display: block;
				padding: 0 $side;
			}
			@media screen and (max-width: $phone){
				padding: 0 $sp-side*2;	
			}
			> li{
				margin: 0 0 0 5vw;
				&.spv{
					display: none;
				}
				@media screen and (max-width: $tab){
					margin: 0;
					border-bottom: rgba(#FFF,.08) 1px solid;
					&.spv{
						display: block;
					}
					&:last-child{
						border: none;
					}
				}
				> a,
				dl dt a{
					color: $white;
					position: relative;
					display: block;
					font-size: 15.5px;
					text-align: center;
					@media screen and (max-width: $tab){
						font-size: 1.44rem;
						text-align: left;
						padding: 24px 0;
					}
					@media screen and (max-width: $phone){
						padding: $sp-side 0;
						font-size: 1.24rem;
					}
					&:after{
						content: attr(data-eng);
						font-size: 10px;
						transform: scale(.8);
						transform-origin: top center;
						display: block;
						opacity: .32;
						letter-spacing: .08em;
						text-transform: uppercase;
						@media screen and (max-width: $tab){
							display: inline-block;
							font-size: .8rem;
							transform: scale(1);
							margin: 0 0 0 20px;
						}
					}
                    &:before{
						@media screen and (min-width: $tab + 1px){
							content: '';
							width: 0;
							height: 4px;
							background: $red;
							position: absolute;
							left: 50%;
							bottom: -17px;
							transform: translateX(-50%);
							transition: width 200ms $ease;
						}
						@media screen and (max-width: $tab){
							content: "\f054";
							font-size: .92rem;
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							position: absolute;
							top: 50%;
							right: 8px;
							transform: translateY(-50%);
							opacity: .4;
						}
                    }
				}
				> a {
					&:hover{
						&:before{
							width: 48px;
							@media screen and (max-width: $tab){
								width: auto;	
							}
						}
					}
				}
				&:first-child{
					margin: 0;
				}
			}
		}
	}
	.subnavi{
		@media screen and (min-width: $tab + 1px){
			&:hover{
				dt{
					&:before,
					&:after{
						display: block;
					}
					&:after{
						animation: arrow 400ms $ease forwards;
						@keyframes arrow{
							0%{
								opacity: 0;
							}	
							100%{
								opacity: 1;
							}	
						}
					}
				}
				dd{
					display: block;
					animation: dd 400ms $ease forwards;
					@keyframes dd{
						0%{
							opacity: 0;
							transform: translateY(10%);
						}
						100%{
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
			dt{
				position: relative;
				&:before{
					content: '';
					width: 150%;
					height: 200%;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%, 0);
					display: none;
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 10px 12px 10px;
					border-color: transparent transparent $gray transparent;
					position: absolute;
					bottom: -5px;
					left: 50%;
					transform: translateX(-50%) translateY(100%);
					display: none;
					opacity: 0;
				}
			}
			dd{
				position: absolute;
				top: $pc-head;
				left: 0;
				width: 100vw;
				height: auto;
				padding: $side;
				background: $gray;
				display: none;
				opacity: 0;
				box-shadow: 0 32px 32px rgba(#000,.12);
				ul{
					@include flex();
					> li{
						width: 20%;
						text-align: center;
						a{
							color: $normal;
							display: block;
							figure{
								img{
									width: 160px;
									border-radius: 8px;
								}
								figcaption{
									font-size: 1.08rem;
									line-height: 1;
									margin: 14px 0 0;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: $tab){
			dd{
				padding: 0;
				margin: 0 0 20px;
				@media screen and (max-width: $phone){
					margin: 0 0 $sp-side;	
				}
				> ul{
					@include flex();
					margin: 0 0 0 -8px;
					@media screen and (max-width: $phone){
						justify-content: flex-start;
						margin: 0 0 0 -12px;
					}
					> li{
						width: 20%;
						padding: 0 0 0 8px;
						@media screen and (max-width: $phone){
							width: 33.3%;
							margin: 12px 0 0;
							padding: 0 0 0 12px;
							&:nth-child(-n+3){
								margin-top: 0;
							}
						}
						a{
							display: block;
							color: $white;
							text-align: center;
						}
						figure{
							img{
								width: 100%;
							}
							figcaption{
								font-size: .88rem;
								margin: 8px 0 0;
								@media screen and (max-width: $phone){
									margin: 6px 0 0;
									font-size: .8rem;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.reserve{
		position: absolute;
		top: 50%;
		right: 190px;
		transform: translateY(-50%);
		@media screen and (max-width: $tab){
			right: 170px;	
		}
		@media screen and (max-width: $phone){
			right: 64px;	
		}
		a{
			background: $red;
			display: block;
			padding: 10px 20px;
			color: #FFF;
			@media screen and (max-width: $tab){
				padding: 9px 20px;
				border-radius: 4px;	
			}
			@media screen and (max-width: $phone){
				border-radius: 999px;	
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:hover{
				background: lighten($red, 8%);
			}
		}
	}
	.contact{
		@media screen and (max-width: $tab){
			display: none;
		}
		&__link{
			background: $red;
			display: block;
			padding: 10px 20px;
			color: #FFF;
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:hover{
				background: lighten($red, 8%);
			}
		}
	}
	
	.openmenu,
	.closemenu{
		display: none;
		@media screen and (max-width: $tab){
			display: block;
			position: absolute;
			top: 50%;
			right: $side;
			border: $red 1px solid;
			background: $red;
			color: #FFF;
			transform: translateY(-50%);
			border-radius: 4px;
			padding: 8px 24px;
			cursor: pointer;
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				right: $sp-side;
				width: 38px;
				height: 38px;
				border-radius: 50%;
				padding: 0;
				@include flex();
				align-items: center;
				justify-content: center;
			}
			&:hover{
				.openmenu__menu{
					&:before{
						width: 50%;
					}
				}
			}
			&__menu{
				width: 20px;
				height: 11px;
				border-top: #FFF 1px solid;
				border-bottom: #FFF 1px solid;
				display: block;
				position: relative;
				margin: 0 10px 0 0;
				@media screen and (max-width: $phone){
					width: 18px;
					margin: 0 auto;
				}
				&:before,
				&:after{
					content: '';
					width: 100%;
					height: 1px;
					background: #FFF;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					transition: width 200ms $ease;
				}
				&:after{
					display: none;
				}
				&__txt{
					@media screen and (max-width: $phone){
						display: none;
					}
				}
			}
		}
	}
	.closemenu{
		@media screen and (max-width: $tab){
			top: 18px;
			transform: translateY(0);
			@media screen and (max-width: $phone){
				top: 14px;	
			}
			&__menu{
				width: 11px;
				border: none;
				@media screen and (max-width: $phone){
					width: 15px;	
				}
				&:before,
				&:after{
					transform: rotate(45deg);
				}
				&:after{
					display: block;
					transform: rotate(-45deg);
				}
			}
		}
	}
}