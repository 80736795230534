@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#221815;
$link:#D02;
$border:#e8e8e8;
$gray: #f4f4f5;
$black: #0a0808;
$white: #edece9;

$red: #b11607;


/* SITE SETTING
------------------------------------------------------------*/
$width: 1080px;
$tab: $width - 1px;
$phone: 640px;

$pc-head:80px;
$sp-head:64px;

$side: 40px;
$sp-side: 16px;

$ease: cubic-bezier(0.85, 0, 0.15, 1);

:root{
	font-size: 14px;
	@media screen and (max-width: $phone){
		font-size: 13px;	
	}
}

@mixin tex(){
	background: url(/images/common/white_tex_2x.png) repeat;
	background-size: 170px;
}

@mixin texRed(){
	background: $red url(/images/common/red_tex_2x.png) repeat;
	background-size: 170px;
}

@mixin ami($color, $size){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
	background-size: $size;
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		0, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        0, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        0, //パディング
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/firstview";
@import "page/index";
@import "page/about";
@import "page/brand";
@import "page/fc";
@import "page/shop";
@import "page/property";
@import "page/media";
@import "page/reserve";
@import "page/article";
@import "page/edit";
@import "page/contact";
@import "page/bnr";
@import "page/lp";

body{
	overflow-x: hidden;
}

#site-wrapper,#site-footer{

}

.fullSize{
	@media screen and (min-width: $tab + 1px){
		max-height: 100vh !important;
	}
}

.site-module{
	&.wrap{
		padding: 88px $side;
		@media screen and (max-width: $tab){
			padding: 64px $side;	
		}
		@media screen and (max-width: $phone){
			padding: 40px $sp-side;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.bg{
			background: $gray;
		}
		&.tex{
			@include tex();
		}
	}
	h2,
	h3{
		&.large{
			font-weight: 700;
			text-align: center;
			line-height: 1;
			position: relative;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;
				font-size: 1.08rem;
			}
			&:before{
				@include oswald;
				font-weight: 700;
				content: attr(data-eng);
				font-size: 3.2rem;
				letter-spacing: .08em;
				display: block;
				margin: 0 0 24px;
				text-transform: uppercase;
				@media screen and (max-width: $tab){
					font-size: 2.8rem;	
				}
				@media screen and (max-width: $phone){
					font-size: 2.2rem;
					margin: 0 0 20px;
				}
			}
			&:after{
				content: '';
				width: 220px;
				height: 3px;
				background: $red;
				position: absolute;
				bottom: 24px;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 50%;
				@media screen and (max-width: $phone){
					width: 80%;
					bottom: 22px;
				}
			}
		}
	}
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 16px;
        font-size: 1.56rem;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $red;
			border-radius: 4px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
    }

	.btnarea{
		margin: 24px 0 0;
		&.center{
			text-align: center;
		}
		a{
			background: $red;
			color: #FFF;
			font-size: 1.14rem;
			padding: 12px 56px;
			min-width: 280px;
			text-align: center;
			display: inline-block;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				display: block;
			}
			&.block{
				text-align: center;
				display: block;
			}
			&:hover{
				background: lighten($red, 8%);
			}
			&:after{
				content: "\f054";
				font-size: .88rem;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				top: 50%;
				right: 16px;
				transform: translateY(-50%);
			}
		}
	}
	.tablestyle{
		border: $border 1px solid;
		border-collapse: collapse;
		width: 100%;
		background: #FFF;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody,
		thead{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				@media screen and (max-width: $phone){
					display: block;
					border-bottom: $border 1px solid;
					&:last-of-type{
						border: none;
					}
				}
				th,td{
					border: $border 1px solid;
					vertical-align: middle;
					padding: 32px;
					text-align: left;
					font-size: 1.08rem;
					@media screen and (max-width: $phone){
						display: block;
						padding: $sp-side;
						border: none;
						font-size: 1rem;
					}
					a{
						color: $normal;
					}
				}
				th{
					width: 240px;
					@media screen and (max-width: $phone){
						width: auto;
						background: lighten($gray, 2%);
						border-bottom: $border 1px solid;
					}
				}
				td{
					a{
						text-decoration: underline;
						&:hover{
							color: $red;
						}
						.window{
							display: inline-block;
							margin: 0 0 0 8px;
							font-size: .72rem;
						}
					}
				}
			}
		}
	}
	.medialist{
		@include flex();
		justify-content: flex-start;
		margin: 0 0 0 -24px;
		@media screen and (max-width: $tab){
			margin: 0;
			display: block;
		}
		&__item{
			width: 50%;
			padding: 0 0 0 20px;
			margin: 20px 0 0;
			@media screen and (max-width: $tab){
				width: auto;
				margin: 0 0 20px;
				padding: 0;
				&:last-child{
					margin: 0;
				}
			}
			&:nth-child(-n+2){
				margin-top: 0;
			}
			&__box{
				@include flex();
				padding: 20px;
				color: $normal;
				background: $gray;
				position: relative;
				@media screen and (max-width: $phone){
					padding: $sp-side;	
				}
				&.white{
					background: #FFF;
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 8px 8px;
					border-color: transparent transparent $red transparent;
					right: 8px;
					bottom: 8px;
					position: absolute;
				}
				&__pic{
					width: 100px;
					height: 100px;
					overflow: hidden;
					@media screen and (max-width: $tab){
						width: 140px;
						height: 140px;
					}
					@media screen and (max-width: $phone){
						width: 80px;
						height: 80px;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&__data{
					width: calc(100% - 100px);
					padding: 0 0 0 20px;
					@media screen and (max-width: $tab){
						width: calc(100% - 140px);
					}
					@media screen and (max-width: $phone){
						width: calc(100% - 80px);	
					}
					time{
						color: #FFF;
						background: $red;
						padding: 1px 0;
						width: 90px;
						text-align: center;
						display: block;
						font-size: .8rem;
						margin: 0 0 6px;
					}
					dl{
						dt{
							font-size: 1.24rem;
							line-height: 1.6;
							font-weight: 700;
							margin: 0 0 4px;
							@media screen and (max-width: $phone){
								font-size: 1rem;	
							}
						}
						dd{
							font-size: .92rem;
							line-height: 1.6;
							text-align: justify;
							@media screen and (max-width: $tab){
								font-size: 1rem;	
							}
							@media screen and (max-width: $phone){
								font-size: .88rem;	
							}
						}
					}
				}
			}
		}
	}
	
	.pager{
		margin: 48px 0 0;
		@include flex();
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		a,
		span{
			$size: 36px;
			width: $size;
			height: $size;
			background: $gray;
			border: $gray 2px solid;
			border-radius: 50%;
			font-size: 1.12rem;
			@include flex();
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			color: $normal;
			transition: all 120ms $ease;
		}
		a{
			&:hover{
				border-color: $red;
				color: $red;
				transform: scale(1.1);
			}
		}
		span{
			background: $red;
			border-color: $red;
			color: #FFF;
		}
	}
	
	.bnrbox{
		background: $gray;
		padding: 24px;
		@include flex();
		justify-content: flex-start;
		margin: 0 0 40px;
		@media screen and (max-width: $phone){
			padding: 16px;
			margin: 0 0 24px;
		}
		&:last-of-type{
			margin: 0;
		}
		figure{
			width: 300px;
			height: 300px;
			overflow: hidden;
			background: #FFF;
			margin: 0 20px 20px 0;
			border: $border 1px solid;
			position: relative;
			@media screen and (max-width: $phone){
				width: 130px;
				height: 130px;
				margin: 0 10px 10px 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
			figcaption{
				position: absolute;
				top: -1px;
				left: -1px;
				background: $red;
				color: #FFF;
				font-size: .96rem;
				padding: 4px 24px;
				@media screen and (max-width: $phone){
					font-size: .72rem;
					padding: 2px 8px;
				}
			}
		}
	}
}

#side{
	display: none;
}

#headerplane{
	padding: $pc-head 0 0;
	@media screen and (max-width: $phone){
		padding: $sp-head 0 0;	
	}
	.head{
		padding: 48px 0;
		background: $gray;
		@media screen and (max-width: $phone){
			padding: 32px $sp-side;	
		}
		h1{
			text-align: center;
			font-size: 2.6rem;
			line-height: 1;
			font-weight: 700;
			@media screen and (max-width: $phone){
				font-size: 1.66rem;	
			}
			&:after{
				@include oswald();
				content: attr(data-eng);
				font-size: 1.24rem;
				color: $red;
				display: block;
				margin: 16px 0 0;
				@media screen and (max-width: $phone){
					margin: 12px 0 0;
					font-size: 1rem;
				}
			}
		}
	}
}


// USE DEMO
.demo{
	background-color: $gray;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 1440px;
	&__view{
		width: 1120px;
		margin: 0 auto;
		overflow: hidden;
		img{
			width: 1440px;
			height: auto;
			opacity: 0;
		}
	}
}

.fade-enter-active {
	transition: 600ms 600ms;
}
.fade-leave-active {
	transition: 600ms 0ms;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.grecaptcha-badge{
	z-index: 2000 !important;
}
