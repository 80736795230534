.site-reserve{
	.reservenav{
		background: $gray;
		@include flex();
		justify-content: center;
		border-radius: 4px;
		padding: 40px 32px;
		transform: translateY(-24px);
		@media screen and (max-width: $phone){
			padding: $sp-side;
			transform: translate(0);
			display: block;
		}
		li{
			margin: 0 8px;
			@media screen and (max-width: $phone){
				margin: 0 0 12px;
				&:last-child{
					margin: 0;
				}
			}
			a{
				background: #FFF;
				border: #FFF 2px solid;
				color: $normal;
				font-weight: 700;
				padding: 13px 56px;
				font-size: 1.2rem;
				border-radius: 4px;
				position: relative;
				box-shadow: 0 0 16px rgba(0,0,0,.06),6px 6px 12px rgba(0,0,0,.04);
				@media screen and (max-width: $phone){
					display: block;
					padding: 13px $sp-side;
				}
				&:hover{
					border-color: $red;
					color: $red;
				}
			}
			&.active{
				a{
					&:after{
						content: '現在ページ';
						font-size: .88rem;
						font-weight: 400;
						color: #FFF;
						background: $red;
						padding: 1px 12px;
						position: absolute;
						top: -16px;
						right: 8px;
						border-radius: 3px;
						@media screen and (max-width: $phone){
							top: 8px;	
						}
					}
				}
			}
		}
	}
	.alt{
		margin: 0 0 48px;
		text-align: center;
		font-size: 1.12rem;
		padding: 24px;
		border: $gray 4px solid;
		border-radius: 4px;
		@media screen and (max-width: $phone){
			padding: $sp-side;
			margin: 24px 0;
			font-size: 1rem;
			text-align: justify;
			br{
				display: none;
			}
		}
	}
	.tellist{
		li{
			border: $gray 8px solid;
			padding: 20px;
			margin: 0 0 32px;
			position: relative;
			@media screen and (max-width: $phone){
				padding: $sp-side;	
			}
			&:last-child{
				margin: 0;
				&:after{
					display: none;
				}
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 20px 16px 0 16px;
				border-color: $gray transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -28px;
				transform: translateX(-50%);
			}
			dl{
				dt{
					font-size: 1.44rem;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 8px;
					border-bottom: $border 1px solid;
					@media screen and (max-width: $phone){
						font-size: 1.24rem;	
					}
					span{
						color: $red;
						display: inline-block;
						margin: 0 8px 0 0;
					}
				}
				dd{
					font-size: 1.08rem;
					@media screen and (max-width: $phone){
						font-size: 1rem;
					}
					&.btn{
						margin: 12px 0 0;
						@include flex();
						justify-content: flex-start;
						@media screen and (max-width: $phone){
							display: block;
						}
						a{
							background: $red;
							border: $red 2px solid;
							border-radius: 4px;
							color: #FFF;
							padding: 8px 16px;
							display: inline-block;
							margin: 0 8px 0 0;
							@media screen and (max-width: $phone){
								margin: 0 0 8px;
								width: auto;
								display: block;
							}
							&:last-child{
								margin-right: 0;
							}
							&:hover{
								background: #FFF;
								color: $red;
							}
						}
					}
				}
			}
		}
	}
	
	// Form
	.two{
		@include flex();
		margin: 0 0 20px;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&__item{
			width: calc(50% - 12px);
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				width: 100%;
				margin: 0 0 8px;
				&:last-of-type{
					margin: 0;
				}
			}
			&__label{
				width: 80px;
				&+*{
					width: calc(100% - 80px);
					padding: 0 0 0 12px;
				}
			}
			&__mini{
				@include flex();
				align-items: center;
				> span{
					width: calc(50% - 12px);
				}
			}
		}
	}
	.flexform{
		@include flex();
		align-items: center;
		justify-content: flex-start;
		&__item{
			margin: 0 40px 0 0;
			@include flex();
			align-items: center;
			justify-content: flex-start;
			@media screen and (max-width: $tab){
				margin: 4px 20px 4px 0;	
			}
			&__ttl{
				margin: 0 12px 0 0;
				@media screen and (max-width: $phone){
					margin: 0 6px 0 0;	
				}
			}
            .wpcf7-form-control-wrap{
                width: 100px;
                margin: 0 12px 0 0;
				@media screen and (max-width: $phone){
					width: 50px;
					margin: 0 6px 0 0;	
				}
            }
		}
	}
	.sml{
		font-size: .96rem;
		&.mt{
			margin: 20px 0 0;
		}
		strong{
			color: $red;
		}
	}
	
	.shopwrapper{
		padding-bottom: 88px;
		@media screen and (max-width: $tab){
			padding-bottom: 64px;
		}
		@media screen and (max-width: $phone){
			padding-bottom: 40px;
		}
		h2.bar{
			font-size: 1.56rem;
			background: $gray;
			text-align: center;
			font-weight: 700;
			padding: 12px 0;
			@media screen and (max-width: $phone){
				font-size: 1.32rem;	
			}
		}
	}
}