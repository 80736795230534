.site-about{
	.philosophy{
		background: $gray;
		padding: 40px;
		padding-bottom: 24px;
		@media screen and (max-width: $phone){
			padding: $sp-side*2 $sp-side $sp-side;	
		}
		dt{
			@include min();
			letter-spacing: .08em;
			font-size: 2.4rem;
			text-align: center;
			line-height: 1;
			font-weight: 700;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 1.66rem;
				margin: 0 0 16px;
			}
		}
		dd{
			padding: 15px;
			background: #FFF;
			font-size: 1.24rem;
			font-weight: 700;
			margin: 0 0 12px;
			text-align: center;
			@media screen and (max-width: $phone){
				font-size: 1rem;	
			}
			&:last-of-type{
				margin: 0;
			}
		}
	}
	
	.mission{
		position: relative;
		@include tex();
		@media screen and (max-width: $phone){
			display: block;	
		}
		&__pic{
			position: absolute;
			top: 0;
			left: -$side;
			width: 64%;
			height: 100%;
			@media screen and (max-width: $phone){
				position: relative;
				top: 0;
				left: 0;
				width: auto;
				height: 56vw;
				transform: translate(0) !important;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__box{
			background: #fff;
			width: 590px;
			position: relative;
			top: 0;
			left: 50%;
			padding: 66px 64px 62px;
			z-index: 2;
			@media screen and (max-width: $tab){
				width: auto;
				max-width: 50%;
				padding: 40px;
			}
			@media screen and (max-width: $phone){
				max-width: 100%;
				width: auto;
				left: 0;
				padding: $sp-side*2 $sp-side*1.5;
				transform: translate(0) !important;
			}
			dl.title{
				dt{
					@include min();
					font-size: 2.4rem;
					font-weight: 700;
					line-height: 1.66;
					@media screen and (max-width: $tab){
						font-size: 2rem;	
					}
					&:before{
						content: 'MISSON';
						@include oswald();
						font-size: .96rem;
						color: $red;
						display: block;
						text-indent: 2px;
					}
				}
				dd{
					font-size: 1.04rem;
					text-align: justify;
					margin: 8px 0 0;
					@media screen and (max-width: $tab){
						font-size: 1rem;	
					}
				}
			}
			dl.m{
				margin: 16px 0 0;
				dt{
					width: 160px;
					padding: 4px 0;
					text-align: center;
					background: $red;
					color: #FFF;
					font-size: 1.08rem;
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
				dd{
					margin: 6px 0 0;
				}
			}
		}
	}
	
	.message{
		position: relative;
		&:before{
			content: '';
			width: 80%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $gray;
		}
		&__relative{
			position: relative;
		}
		&__pic{
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			@media screen and (max-width: $tab){
				position: relative;
				width: 100%;
				height: 56vw;
			}
			img{
				width: 100%;
				height: 100%;
				object-position: center;
				object-fit: cover;
			}
		}
		.greeting{
			width: 50%;
			padding: 40px;
			padding-right: 64px;
			padding-left: 0;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 40px;
				background: #FFF;
			}
			@media screen and (max-width: $phone){
				padding: $sp-side*2 $sp-side*1.5;	
			}
			&__title{
				margin: 0 0 24px;
				font-weight: 700;
				@media screen and (max-width: $phone){
					margin: 0 0 16px;	
				}
			}
			&__context{
				&__dt{
					@include min();
					font-size: 1.96rem;
					line-height: 1.6;
					transform: translateX(-2px);
					font-weight: 700;
					@media screen and (max-width: $phone){
						font-size: 1.32rem;	
					}
				}
				&__dd{
					font-size: 1rem;
					text-align: justify;
					line-height: 2;
					margin: 18px 0 24px;
					@media screen and (max-width: $phone){
						margin: 15px 0 20px;	
					}
				}
				&__name{
					@include flex();
					justify-content: flex-end;
					align-items: flex-end;
					@include min();
					font-size: .96rem;
					line-height: 1;
					font-weight: 700;
					text-align: right;
					@media screen and (max-width: $phone){
						font-size: .88rem;	
					}
					strong{
						font-size: 2rem;
						display: inline-block;
						margin: 0 0 0 16px;
					}
					.ceoname{
						width: 140px;
						margin: 0 0 0 12px;
						@media screen and (max-width: $phone){
							width: 100px;	
						}
						img{
							width: 100%;
						}
					}
				}
			}
		}
	}
	
	.devlist{
		&__item{
			padding: 0 0 32px;
			margin: 0 0 32px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				padding: 0 0 20px;
				margin: 0 0 20px;
			}
			&:last-child{
				margin-bottom: 0;
				padding-bottom: 0;
				border: none;
			}
			a{
				color: $normal;
				position: relative;
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
				.thumb{
					width: 400px;
					@media screen and (max-width: $phone){
						width: auto;	
					}
					img{
						width: 100%;
					}
				}
				&:hover{
					.data{
						dl{
							dd.crone{
								background: lighten($red,4%);
							}
						}
					}
				}
				.icon{
					width: 80px;
					padding: 6px;
					background: #FFF;
					border-radius: 50%;
					overflow: hidden;
					margin: 0 auto;
					position: absolute;
					top: 0;
					left: 0;
					transform: translate(-16px, -16px);
					box-shadow: 0 0 24px rgba($normal,.08);
					@media screen and (max-width: $phone){
						width: 56px;
						height: 56px;
						transform: translate(-8px, -8px);
					}
					img{
						width: 100%;
						border-radius: 50%;
					}
				}
				.data{
					width: calc(100% - 400px);
					padding: 0 0 0 40px;
					@media screen and (max-width: $phone){
						width: auto;
						margin: 12px 0 0;
						padding: 0;
					}
					dl{
						dt{
							font-size: 1.72rem;
							font-weight: 700;
							margin: 0 0 8px;
							@media screen and (max-width: $phone){
								font-size: 1.44rem;
								margin: 0 0 4px;
								text-align: center;
							}
						}
						dd{
							font-size: 1rem;
							line-height: 1.66;
							text-align: justify;
							@media screen and (max-width: $phone){
								padding: 0 $sp-side;	
							}
							&.crone{
								font-size: 1.12rem;
								margin: 18px 0 0;
								padding: 12px 64px;
								position: relative;
								text-align: center;
								background: $red;
								color: #FFF;
								left: 0;
								bottom: 0;
								display: inline-block;
								@media screen and (max-width: $phone){
									display: block;	
								}
								i{
									position: absolute;
									top: 50%;
									right: 15px;
									transform: translateY(-50%);
									font-size: .88rem;
								}
							}
						}
					}
				}
			}
		}
	}
	.social{
		width: calc(100% + #{$side*2});
		transform: translateX(-1 * $side);
		@media screen and (max-width: $tab){
			width: auto;
			transform: translate(0);
		}
		&__list{
			@include flex();
			@media screen and (max-width: $tab){
				display: block;	
			}
			li{
				width: 33%;
				height: 18vw;
				min-height: 300px;
				background: #000;
				@include flex();
				align-items: center;
				justify-content: center;
				padding: 32px 48px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				position: relative;
				@media screen and (max-width: $tab){
					width: 100%;
					min-height: 1px;
					height: auto;
					padding: 80px 32px;
					margin: 0 0 8px;
					display: block;
					&:last-child{
						margin: 0;
					}
				}
				@media screen and (max-width: $phone){
					padding: 64px 24px;
					padding: $sp-side*2;
				}
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: #000;
					opacity: .64;
				}
				&:nth-child(2){
					width: 34%;
					@media screen and (max-width: $tab){
						width: auto;	
					}
				}
				dl{
					max-width: 400px;
					margin: 0 auto;
					text-align: center;
					color: #FFF;
					position: relative;
					z-index: 2;
					dt{
						font-size: 1.24rem;
						font-weight: 700;
						margin: 0 0 12px;
						@media screen and (max-width: $tab){
							font-size: 1.44rem;	
						}
						@media screen and (max-width: $phone){
							font-size: 1.32rem;
							margin: 0 0 8px;
						}
					}
					@media screen and (max-width: $phone){
						dd{
							text-align: justify;
						}	
					}
				}
			}
		}
	}
	
	ol.history{
		position: relative;
        &:before{
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 6px;
            border-left: $red 2px solid;
            z-index: 2;
        }
		li{
			list-style: none;
			margin: 0 0 20px;
			padding: 0 0 20px;
			border-bottom: $border 1px solid;
			&:last-child{
				margin: 0;
			}
			dl{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				font-size: 1.08rem;
				position: relative;
				padding: 0 0 0 48px;
				&:before{
					content: '';
					width: 16px;
					height: 16px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: #FFF;
					border: $red 2px solid;
					z-index: 10;
				}
				&:after{
					content: '';
					position: absolute;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 4px;
					transform: translateY(-50%);
					background: $red;
					z-index: 11;
				}
				dt{
					width: 100px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						width: 72px;	
					}
				}
				dd{
					width: calc(100% - 200px);
					padding-left: 40px;
					@media screen and (max-width: $phone){
						width: 100%;
						padding: 8px 0 0;
					}
					&:first-of-type{
						width: 100px;
						background: $gray;
						padding: 4px 0;
						text-align: center;
						@media screen and (max-width: $phone){
							padding: 2px 0;
							font-size: .96rem;
						}
					}
				}
			}
		}
	}
}