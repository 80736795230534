.site-brand{
	.relative{
		position: relative;
		@media screen and (max-width: $phone){
			background: $gray;
		}
		.ami{
			z-index: 2;
			@media screen and (max-width: $phone){
				display: none;
			}
		}
	}
    .ami{
        @include ami(rgba($red,.99), 12px);
        width: 20vw;
        height: 20vw;
        position: absolute;
		@media screen and (max-width: $phone){
			display: none;
		}
        &.pos1{
            left: 4vw;
            top: 10vh;
			@media screen and (max-width: $tab){
				left: 2vw;	
			}
        }
        &.pos2{
            right: 4vw;
            bottom: 10vh;
			@media screen and (max-width: $tab){
				right: 2vw;	
			}
        }
    }
	.preferences{
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;	
		}
		&__box{
			figure{
				img{
					width: 100%;
				}
			}
			dl{
				background: #FFF;
				margin: 0 8%;
				padding: 32px 8%;
				transform: translateY(-88px);
				box-shadow: 0 0 24px rgba(#000,.04), 8px 8px 16px rgba(#000,.06);
				position: relative;
				@media screen and (max-width: $phone){
					transform: translateY(0);
					margin: 0;
					padding: $sp-side*2 $sp-side 0;
					box-shadow: none;
				}
				&:before{
					content: '';
					width: 20px;
					height: 20px;
					background: $red;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateY(-50%) translateX(-50%) rotate(45deg);
				}
				dt{
					@include min();
					text-align: center;
					font-size: 2rem;
					font-weight: 700;
					margin: 0 0 8px;
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						margin: 0 0 4px;
					}
				}
				dd{
					font-size: 1.04rem;
					line-height: 1.8;
					text-align: justify;
					@media screen and (max-width: $phone){
						font-size: 1rem;	
					}
				}
			}
		}
	}
	
	.pref{
		position: relative;
		&:before,
		&:after{
			content: '';
			width: calc(72% + #{$side});
			height: calc(100% + 88px * 2);
			position: absolute;
			top: -88px;
			right: -$side;
			@include tex();
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&:after{
			top: auto;
			bottom: 0;
			height: 50%;
			width: calc(100% + #{$side*2});
			z-index: 1;
		}
		&__pic{
			width: calc(72% + #{$side});
			height: 100%;
			left: -$side;
			top: 0;
			position: absolute;
			z-index: 2;
			@media screen and (max-width: $phone){
				width: auto;
				height: 56vw;
				position: relative;
				left: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__data{
			position: relative;
			width: 560px;
			padding: 88px 0;
			margin-left: 50%;
			z-index: 2;
			@media screen and (max-width: $tab){
				width: auto;	
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0;
			}
			&__box{
				background: #FFF;
				padding: 56px;
				@media screen and (max-width: $tab){
					padding: 40px;	
				}
				@media screen and (max-width: $phone){
					padding: $sp-side;
					transform: translate(0) !important;
				}
				h3{
                    @include min();
                    font-size: 2.4rem;
                    font-weight: 700;
					@media screen and (max-width: $tab){
						font-size: 1.8rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 1.72rem;
						text-align: center;
						margin: 0 0 6px;
					}
					&:before{
						content: attr(data-eng);
						@include oswald();
						text-transform: uppercase;
						font-size: .88rem;
						color: $red;
						display: block;
						text-indent: .24em;
					}
				}
				p{
					font-size: 1.04rem;
					text-align: justify;
					@media screen and (max-width: $tab){
						font-size: 1rem;	
					}
				}
			}
		}
		&.rvs{
			@include flex();
			justify-content: flex-end;
			&:before{
				right: 0;
				left: -$side;
			}
			&:after{
				display: none;
			}
			.pref__pic{
				left: auto;
				right: -$side;
				@media screen and (max-width: $phone){
					left: 0;
					right: auto;
				}
			}
			.pref__data{
				margin-left: 0;
				margin-right: 50%;
				@media screen and (max-width: $phone){
					margin: 0;	
				}
			}
		}
	}
	
	.franksn{
		background: url(/images/brand/brand.jpg) no-repeat center / cover;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		color: #FFF;
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .64;
			z-index: 1;
		}
		dl{
			text-align: center;
			position: relative;
			z-index: 2;
			font-size: 1.16rem;
			dt{
				font-size: 2rem;
				font-weight: 700;
				margin: 0 0 8px;
			}
		}
	}
}