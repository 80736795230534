.site-lp{
	.lp{
		&__title{
			font-size: 2.8rem;
			font-weight: 700;
			text-align: center;
			line-height: 1.56;
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 1.66rem;
				margin: 0 0 24px;
			}
		}
		&__subtitle{
			font-size: 1.16rem;
			text-align: center;
			line-height: 1.66;
			font-weight: 700;
			transform: translateY(-4px);
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
		}
		&__full{
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			img{
				width: 100%;
				height: auto;
			}
		}
		&__clum{
			@include flex();
			margin: 32px 0 0;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;
				display: block;
			}
			&:first-of-type{
				margin: 0;
			}
			&__pic{
				width: 420px;
				@media screen and (max-width: $phone){
					width: auto;
				}
				+ .lp__clum__data{
					width: calc(100% - 420px);
					padding: 8px 0 0 40px;
					@media screen and (max-width: $phone){
						width: auto;
						padding: 16px 0 0;
					}
				}
				&.right{
					order: 2;
					+ .lp__clum__data{
						order: 1;
						padding-left: 0;
						padding-right: 40px;
						@media screen and (max-width: $phone){
							padding-right: 0;	
						}
					}
				}
				img{
					width: 100%;
					height: auto;
				}
			}
			&__data{
				font-size: 1.04rem;
				h3{
					font-size: 1.56rem;
					font-weight: 700;
					line-height: 1.56;
					margin: 0 0 14px;
					padding: 0 0 12px;
					border-bottom: $border 1px solid;
					@media screen and (max-width: $phone){
						font-size: 1.32rem;	
					}
				}
				strong{
					font-size: 1.08rem;
					font-weight: 700;
					color: $red;
				}
				a{
					text-decoration: underline;
				}
			}
		}
		&__alt{
			margin: 32px 0 0;
			background: $gray;
			text-align: center;
			padding: 24px;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;
				padding: 15px;
			}
		}
		&__btn{
			margin: 32px 0 0;
			text-align: center;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;	
			}
			a{
				display: inline-block;
				background: $red;
				border: $red 2px solid;
				color: #FFF;
				font-size: 1.32rem;
				font-weight: 700;
				padding: 14px 80px;
				@media screen and (max-width: $phone){
					display: block;
					padding: 12px 0;
					text-align: center;
				}
				&:hover{
					background: #FFF;
					color: $red;
				}
			}
		}
	}
}