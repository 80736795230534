.site-media{
	.medialist{
		@include flex();
		margin: 0 0 0 -32px;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0;
		}
		li{
			width: 33.3%;
			padding: 0 0 0 32px;
			margin: 56px 0 0;
			&:nth-child(-n+3){
				margin-top: 0;
			}
			@include flex();
			@media screen and (max-width: $tab){
				width: auto;
				padding: 0;
				margin: 0 0 20px;
				padding: 0 0 20px;
				border-bottom: $border 1px solid;
				&:last-child{
					margin: 0;
					padding: 0;
					border: none;
				}
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 16px;
				padding: 0 0 16px;
				border-bottom: $border 1px solid;
			}
			a{
				display: block;
				width: 100%;
				height: 100%;
				color: $normal;
				@media screen and (max-width: $tab){
					@include flex();	
				}
				&:hover{
					figure{
						img{
							transform: scale(1.06);
						}
						&:before{
							opacity: 1;
						}
						&:after{
							opacity: 1;
							top: 50%;
						}
					}
				}
			}
			figure{
				width: 100%;
				height: 200px;
				overflow: hidden;
				position: relative;
				@media screen and (max-width: $tab){
					width: 300px;
				}
				@media screen and (max-width: $phone){
					width: 100px;
					height: 75px;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					transition: transform 400ms $ease;
				}
				&:before,
				&:after{
					content: '';
					position: absolute;
					opacity: 0;
					transition: opacity 400ms $ease, top 400ms $ease;
				}
				&:before{
					width: 100%;
					height: 100%;
					background: rgba(#000,.32);
					top: 0;
					left: 0;
				}
				&:after{
					content: '詳細を見る';
					top: 60%;
					left: 50%;
					background: #FFF;
					border-radius: 999px;
					padding: 2px 24px;
					transform: translate(-50%, -50%);
				}
			}
            dl{
                margin: 16px 0 0;
				@media screen and (max-width: $tab){
					margin: 0;
					width: calc(100% - 300px);
					padding: 0 0 0 32px;
				}
				@media screen and (max-width: $phone){
					width: calc(100% - 100px);
					padding: 0 0 0 20px;
				}
                dt{
                    font-size: 1.24rem;
					line-height: 1.66;
                    font-weight: 700;
					margin: 0 0 6px;
					@media screen and (max-width: $phone){
						font-size: 1.12rem;	
					}
                }
				dd{
					font-size: .96rem;
					line-height: 1.66;
					text-align: justify;
					@media screen and (max-width: $phone){
						display: none;
					}
					&.time{
						background: $red;
						padding: 1px 0;
						color: #FFF;
						display: inline-block;
						width: 100px;
						text-align: center;
						margin: 0 0 8px;
						@media screen and (max-width: $phone){
							font-size: .88rem;	
						}
					}
				}
            }
		}
	}
}