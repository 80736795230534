.site-contact{
	.localnav{
        padding: 24px;
        background: $gray;
		margin: 0 0 24px;
		transform: translateY(-24px);
		@media screen and (max-width: $phone){
			transform: translateY(0);
			padding: $sp-side;
		}
		ul{
			text-align: center;
			@include flex();
			margin: 0 0 0 -24px;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			li{
				width: 33.3%;
				padding: 0 0 0 24px;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 12px;
					&:last-child{
						margin: 0;
					}
				}
				&.active{
					a{
						position: relative;
						&:before{
							content: '選択中';
							position: absolute;
							top: 0;
							right: 15px;
							transform: translateY(-50%);
							font-size: .88rem;
							border-radius: 4px;
							padding: 2px 10px;
							background: $red;
							color: #FFF;
						}
					}
				}
				a{
					display: block;
					background: #FFF;
					border: #FFF 2px solid;
					color: $normal;
					font-size: 1.12rem;
					font-weight: 700;
					padding: 12px 0;
					border-radius: 4px;
					box-shadow: 0 0 16px rgba(#000,.06), 6px 6px 12px rgba(#000,.04);
					@media screen and (max-width: $tab){
						font-size: 1rem;	
					}
					&:hover{
						border-color: $red;
						color: $red;
					}
				}
			}
		}
	}
	
	.formstyle{
		border: none;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;	
				}
				th,
				td{
					text-align: left;
					vertical-align: middle;
					padding: 32px 0;
					font-size: 1.12rem;
					@media screen and (max-width: $phone){
						display: block;
						padding: $sp-side 0;
						font-size: 1.04rem;
					}
				}
				th{
					width: 240px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						width: auto;
						padding-bottom: 0;
					}
					&.hiss{
						&:after{
							content: '必須項目';
							float: right;
							background: $red;
							color: #FFF;
							font-size: .88rem;
							font-weight: 400;
							padding: 2px 8px;
							border-radius: 3px;
						}
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width: $phone){
						padding-left: 0;	
					}
				}
			}
		}
		input[type=text],	
		input[type=email],	
		input[type=tel],
		input[type=date],
		select,	
		textarea{
			@include font();
			font-size: 1.08rem;
			background: $gray;
			border: none;
			padding: 8px 10px;
			display: block;
			width: 100%;
			border-radius: 3px;
			outline: none;
			transition: all 200ms $ease;
			@media screen and (max-width: $phone){
				-webkit-appearance: none;
			}
			&:focus{
				background: #FFF;
				transform: scale(1.02);
				box-shadow: 0 0 32px rgba(#000,.12), 8px 8px 24px rgba(#000,.08);
				position: relative;
				z-index: 2;
			}
		}
		textarea{
			height: 200px;
		}
	}
	.sendarea{
		margin: 40px 0 0;
		text-align: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		input{
			@include font();
			display: inline-block;
			color: #FFF;
			border: none;
			background: $red;
			border: $red 2px solid;
			padding: 14px 96px;
			font-size: 1.44rem;
			font-weight: 700;
			border-radius: 3px;
			cursor: pointer;
			transition: all 200ms $ease;
			@media screen and (max-width: $phone){
				-webkit-appearance: none;
				display: block;
				padding: 14px 0;
				width: 100%;
			}
			&:hover{
				background: #FFF;
				color: $red;
			}
		}
	}
	
	.privacy{
		margin: 32px 0 0;
		background: $gray;
		padding: 32px 40px;
		border-radius: 4px;
		overflow: auto;
		line-height: 1.8;
		height: 260px;
		position: relative;
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		&:after{
			content: '';
			height: 32px;
			background: linear-gradient(to bottom,  rgba($gray,0) 0%,rgba($gray,1) 100%);
			position: sticky;
			left: 0;
			display: block;
			bottom: -32px;
			z-index: 2;
		}
		h4{
			font-size: 1.32rem;
			font-weight: 700;
			margin: 0 0 12px;
			padding: 0 0 8px;
			border-bottom: #FFF 2px solid;
			small{
				display: inline-block;
				font-size: .96rem;
				margin: 0 0 0 20px;
				@media screen and (max-width: $phone){
					display: block;
					margin: 2px 0 0;
				}
			}
		}
		&__mb{
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
		}
		dl{
			margin: 0 0 48px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			dt{
				font-size: 1.24rem;
				font-weight: 700;
				border-bottom: #FFF 2px solid;
				margin: 0 0 12px;
				padding: 0 0 8px;
			}
			dd{
				margin: 20px 0 0;
				@media screen and (max-width: $phone){
					margin: 16px 0 0;	
				}
				ol{
					li{
						padding: 0 0 0 32px;
						list-style: none;
						position: relative;
						margin: 0 0 12px;
						&:last-child{
							margin: 0;
						}
						@for $i from 1 through 10{
							&:nth-child(#{$i}){
								&:before{
									content: '#{$i}';
									background: darken($gray, 24%);
									color: #FFF;
									width: 20px;
									height: 20px;
									font-size: .8rem;
									position: absolute;
									top: 3px;
									left: 0;
									@include flex();
									align-items: center;
									justify-content: center;
									border-radius: 3px;
								}
							}
						}
					}
				}
				h5{
					font-weight: 700;
				}
				.indent{
					margin: 16px 0;
					padding: 15px 24px;
					background: #FFF;
					line-height: 1.88;
					border-radius: 3px;
				}
			}
		}
	}
}